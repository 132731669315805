//----------------------------------------------------------------------------
// 3 Vector

export class Vec3 {

    vec3 : Float32Array;

    set(x : number, y : number, w : number) : Vec3 {
        let v = this.vec3;
        v[0] = x; v[1] = y; v[2] = w;
        return this;
    }

    pre_mul_mat3(that : Mat3) : Vec3 {
        let v = this.vec3;
        const m = that.mat3
        , v0 = v[0], v1 = v[1], v2 = v[2]
        ;

        v[0] = v0*m[0] + v1*m[3] + v2*m[6];
        v[1] = v0*m[1] + v1*m[4] + v2*m[7];
        v[2] = v0*m[2] + v1*m[5] + v2*m[8];
        return this;
    }

    post_mul_mat3(that : Mat3) : Vec3 {
        let v = this.vec3;
        const m = that.mat3
        , v0 = v[0], v1 = v[1], v2 = v[2]
        ;

        v[0] = v0*m[0] + v1*m[1] + v2*m[2];
        v[1] = v0*m[3] + v1*m[4] + v2*m[5];
        v[2] = v0*m[6] + v1*m[7] + v2*m[8];
        return this;
    }

    add(that : Vec3) : Vec3 {
        let v = this.vec3;
        const u = that.vec3;
        v[0] += u[0];
        v[1] += u[1];
        v[2] += u[2];
        return this;
    }

    sub(that : Vec3) : Vec3 {
        let v = this.vec3;
        const u = that.vec3;
        v[0] -= u[0];
        v[1] -= u[1];
        v[2] -= u[2];
        return this;
    }

    copy(that : Vec3) : Vec3 {
        let v = this.vec3;
        const u = that.vec3;
        v[0] = u[0];
        v[1] = u[1];
        v[2] = u[2];
        return this;
    }

    normalise() : Vec3 {
        let v = this.vec3;
        v[0] /= v[2];
        v[1] /= v[2];
        v[2] = 1;
        return this;
    }

    in_range(m : Vec3, n : Vec3) : boolean {
        let v = this.vec3
        , f = m.vec3
        , l = n.vec3
        ;

        return (f[0] <= v[0]) && (v[0] < l[0])
            && (f[1] <= v[1]) && (v[1] < l[1]);
    }

    constructor() {
        this.vec3 = new Float32Array(3);
    }
}

//----------------------------------------------------------------------------
// 4 Vector

export class Vec4 {

  vec4 : Float32Array;

  set(x : number, y : number, z : number , w : number) : Vec4 {
    let v = this.vec4;
    v[0] = x; v[1] = y; v[2] = z; v[3] = w;
    return this;
  }

  pre_mul_mat4(that : Mat4) : Vec4 {
    let v = this.vec4;
    const m = that.mat4
    , v0 = v[0], v1 = v[1], v2 = v[2], v3 = v[3]
    ;

    v[0] = v0*m[0] + v1*m[4] + v2*m[8] + v3*m[12];
    v[1] = v0*m[1] + v1*m[5] + v2*m[9] + v3*m[13];
    v[2] = v0*m[2] + v1*m[6] + v2*m[10] + v3*m[14];
    v[3] = v0*m[3] + v1*m[7] + v2*m[11] + v3*m[15];
    return this;
  }
  
  post_mul_mat4(that : Mat4) : Vec4 {
    let v = this.vec4;
    const m = that.mat4
    , v0 = v[0], v1 = v[1], v2 = v[2], v3 = v[3]
    ;

    v[0] = v0*m[0] + v1*m[1] + v2*m[2] + v3*m[3];
    v[1] = v0*m[4] + v1*m[5] + v2*m[6] + v3*m[7];
    v[2] = v0*m[8] + v1*m[9] + v2*m[10] + v3*m[11];
    v[3] = v0*m[12] + v1*m[13] + v2*m[14] + v3*m[15];
    return this;
  }

  add(that : Vec4) : Vec4 {
    let v = this.vec4;
    const u = that.vec4;
    v[0] += u[0];
    v[1] += u[1];
    v[2] += u[2];
    v[3] += u[3];
    return this;
  }

  sub(that : Vec4) : Vec4 {
    let v = this.vec4;
    const u = that.vec4;
    v[0] -= u[0];
    v[1] -= u[1];
    v[2] -= u[2];
    v[3] -= u[3];
    return this;
  }

  copy(that : Vec4) : Vec4 {
    let v = this.vec4;
    const u = that.vec4;
    v[0] = u[0];
    v[1] = u[1];
    v[2] = u[2];
    v[3] = u[3];
    return this;
  }

  in_range(m : Vec4, n : Vec4) : boolean {
    const v = this.vec4
    , f = m.vec4
    , l = n.vec4
    ;

    return (f[0] <= v[0]) && (v[0] < l[0])
        && (f[1] <= v[1]) && (v[1] < l[1])
        && (f[2] <= v[2]) && (v[2] < l[2]);
  }

  constructor() {
    this.vec4 = new Float32Array(4);
  }
}

//----------------------------------------------------------------------------
// 3x3 Matrix

export class Mat4 {

  mat4 : Float32Array;

  transpose() : Mat4 {
    let m = this.mat4;
    const m01 = m[1], m02 = m[2], m03 = m[3]
    , m12 = m[6], m13 = m[7], m23 = m[11]
    ;

    m[1] = m[4]; m[2] = m[8]; m[3] = m[12];
    m[4] = m01; m[6] = m[9]; m[7] = m[13];
    m[8] = m02; m[9] = m12; m[11] = m[14];
    m[12] = m03; m[13] = m13; m[14] = m23;
    return this;
  }

  copy(that : Mat4) : Mat4 {
    let m = this.mat4;
    const n = that.mat4;
    m[0] = n[0]; m[1] = n[1]; m[2] = n[2]; m[3] = n[3];
    m[4] = n[4]; m[5] = n[5]; m[6] = n[6]; m[7] = n[7];
    m[8] = n[8]; m[9] = n[9]; m[10] = n[10]; m[11] = n[11];
    m[12] = n[12]; m[13] = n[13]; m[14] = n[14]; m[15] = n[15];
    return this;
  }

  set_translate(x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    m[12] = x; m[13] = y; m[14] = z;
    return this;
  }

  invert() : Mat4 {
    let m = this.mat4;
    const a00 = m[0], a01 = m[1], a02 = m[2], a03 = m[3]
    , a10 = m[4], a11 = m[5], a12 = m[6], a13 = m[7]
    , a20 = m[8], a21 = m[9], a22 = m[10], a23 = m[11]
    , a30 = m[12], a31 = m[13], a32 = m[14], a33 = m[15]
    , b00 = a00*a11 - a01*a10, b01 = a00*a12 - a02*a10
    , b02 = a00*a13 - a03*a10, b03 = a01*a12 - a02*a11
    , b04 = a01*a13 - a03*a11, b05 = a02*a13 - a03*a12
    , b06 = a20*a31 - a21*a30, b07 = a20*a32 - a22*a30
    , b08 = a20*a33 - a23*a30, b09 = a21*a32 - a22*a31
    , b10 = a21*a33 - a23*a31, b11 = a22*a33 - a23*a32
    , det = 1.0 / (b00*b11 - b01*b10 + b02*b09 + b03*b08 - b04*b07 + b05*b06)
    ;

    m[0] = (a11*b11 - a12*b10 + a13*b09) * det;
    m[1] = (a02*b10 - a01*b11 - a03*b09) * det;
    m[2] = (a31*b05 - a32*b04 + a33*b03) * det;
    m[3] = (a22*b04 - a21*b05 - a23*b03) * det;
    m[4] = (a12*b08 - a10*b11 - a13*b07) * det;
    m[5] = (a00*b11 - a02*b08 + a03*b07) * det;
    m[6] = (a32*b02 - a30*b05 - a33*b01) * det;
    m[7] = (a20*b05 - a22*b02 + a23*b01) * det;
    m[8] = (a10*b10 - a11*b08 + a13*b06) * det;
    m[9] = (a01*b08 - a00*b10 - a03*b06) * det;
    m[10] = (a30*b04 - a31*b02 + a33*b00) * det;
    m[11] = (a21*b02 - a20*b04 - a23*b00) * det;
    m[12] = (a11*b07 - a10*b09 - a12*b06) * det;
    m[13] = (a00*b09 - a01*b07 + a02*b06) * det;
    m[14] = (a31*b01 - a30*b03 - a32*b00) * det;
    m[15] = (a20*b03 - a21*b01 + a22*b00) * det;
    return this;
  }

  set_identity() : Mat4 {
    let m = this.mat4;
    m[0] = 1; m[1] = 0; m[2] = 0; m[3] = 0;
    m[4] = 0; m[5] = 1; m[6] = 0; m[7] = 0;
    m[8] = 0; m[9] = 0; m[10] = 1; m[11] = 0;
    m[12] = 0; m[13] = 0; m[14] = 0; m[15] = 1;
    return this;
  }

  set_left_identity() : Mat4 {
    let m = this.mat4;
    m[0] = 1; m[1] = 0; m[2] = 0; m[3] = 0;
    m[4] = 0; m[5] = -1; m[6] = 0; m[7] = 0;
    m[8] = 0; m[9] = 0; m[10] = 1; m[11] = 0;
    m[12] = 0; m[13] = 0; m[14] = 0; m[15] = 1;
    return this;
  }

  set_transpose(that : Mat4) : Mat4 {
    let m = this.mat4;
    const n = that.mat4;
    m[1] = n[4]; m[2] = n[8]; m[3] = n[12];
    m[4] = n[1]; m[6] = n[9]; m[7] = n[13];
    m[8] = n[2]; m[9] = n[6]; m[11] = n[14];
    m[12] = n[3]; m[13] = n[7]; m[14] = n[11];
    return this;
  }

  set_invert(that : Mat4) : Mat4 {
    let m = this.mat4;
    const n = that.mat4
    , a00 = n[0], a01 = n[1], a02 = n[2], a03 = n[3]
    , a10 = n[4], a11 = n[5], a12 = n[6], a13 = n[7]
    , a20 = n[8], a21 = n[9], a22 = n[10], a23 = n[11]
    , a30 = n[12], a31 = n[13], a32 = n[14], a33 = n[15]
    , b00 = a00*a11 - a01*a10, b01 = a00*a12 - a02*a10
    , b02 = a00*a13 - a03*a10, b03 = a01*a12 - a02*a11
    , b04 = a01*a13 - a03*a11, b05 = a02*a13 - a03*a12
    , b06 = a20*a31 - a21*a30, b07 = a20*a32 - a22*a30
    , b08 = a20*a33 - a23*a30, b09 = a21*a32 - a22*a31
    , b10 = a21*a33 - a23*a31, b11 = a22*a33 - a23*a32
    , det = 1.0 / (b00*b11 - b01*b10 + b02*b09 + b03*b08 - b04*b07 + b05*b06)
    ;

    m[0] = (a11*b11 - a12*b10 + a13*b09) * det;
    m[1] = (a02*b10 - a01*b11 - a03*b09) * det;
    m[2] = (a31*b05 - a32*b04 + a33*b03) * det;
    m[3] = (a22*b04 - a21*b05 - a23*b03) * det;
    m[4] = (a12*b08 - a10*b11 - a13*b07) * det;
    m[5] = (a00*b11 - a02*b08 + a03*b07) * det;
    m[6] = (a32*b02 - a30*b05 - a33*b01) * det;
    m[7] = (a20*b05 - a22*b02 + a23*b01) * det;
    m[8] = (a10*b10 - a11*b08 + a13*b06) * det;
    m[9] = (a01*b08 - a00*b10 - a03*b06) * det;
    m[10] = (a30*b04 - a31*b02 + a33*b00) * det;
    m[11] = (a21*b02 - a20*b04 - a23*b00) * det;
    m[12] = (a11*b07 - a10*b09 - a12*b06) * det;
    m[13] = (a00*b09 - a01*b07 + a02*b06) * det;
    m[14] = (a31*b01 - a30*b03 - a32*b00) * det;
    m[15] = (a20*b03 - a21*b01 + a22*b00) * det;
    return this;
  }

  set_mul(left : Mat4, right : Mat4) : Mat4 {
    let m = this.mat4;
    const a = left.mat4, b = right.mat4
    , a00 = a[0], a01 = a[1], a02 = a[2], a03 = a[3]
    , a10 = a[4], a11 = a[5], a12 = a[6], a13 = a[7]
    , a20 = a[8], a21 = a[9], a22 = a[10], a23 = a[11]
    , a30 = a[12], a31 = a[13], a32 = a[14], a33 = a[15]
    ;
    let b0 = b[0], b1 = b[1], b2 = b[2], b3 = b[3];

    m[0] = b0*a00 + b1*a10 + b2*a20 + b3*a30;
    m[1] = b0*a01 + b1*a11 + b2*a21 + b3*a31;
    m[2] = b0*a02 + b1*a12 + b2*a22 + b3*a32;
    m[3] = b0*a03 + b1*a13 + b2*a23 + b3*a33;
    b0 = b[4], b1 = b[5], b2 = b[6], b3 = b[7];
    m[4] = b0*a00 + b1*a10 + b2*a20 + b3*a30;
    m[5] = b0*a01 + b1*a11 + b2*a21 + b3*a31;
    m[6] = b0*a02 + b1*a12 + b2*a22 + b3*a32;
    m[7] = b0*a03 + b1*a13 + b2*a23 + b3*a33;
    b0 = b[8], b1 = b[9], b2 = b[10], b3 = b[11];
    m[8] = b0*a00 + b1*a10 + b2*a20 + b3*a30;
    m[9] = b0*a01 + b1*a11 + b2*a21 + b3*a31;
    m[10] = b0*a02 + b1*a12 + b2*a22 + b3*a32;
    m[11] = b0*a03 + b1*a13 + b2*a23 + b3*a33;
    b0 = b[12], b1 = b[13], b2 = b[14], b3 = b[15];
    m[12] = b0*a00 + b1*a10 + b2*a20 + b3*a30;
    m[13] = b0*a01 + b1*a11 + b2*a21 + b3*a31;
    m[14] = b0*a02 + b1*a12 + b2*a22 + b3*a32;
    m[15] = b0*a03 + b1*a13 + b2*a23 + b3*a33;
    return this;
  }

  set_perspective(fov : number, aspect : number, near : number, far : number) : Mat4 {
    let m = this.mat4
    const f = 1 / Math.tan(fov / 2), nf = 1 / (near - far);
    m[0] = f / aspect; m[1] = 0; m[2] = 0; m[3] = 0;
    m[4] = 0; m[5] = f; m[6] = 0; m[7] = 0;
    m[8] = 0; m[9] = 0; m[10] = (far + near) * nf; m[11] = -1;
    m[12] = 0; m[13] = 0; m[14] = 2 * far * near * nf; m[15] = 0;
    return this;
  }

  pre_mul(that : Mat4) : Mat4 {
    let m = this.mat4;
    const n = that.mat4
    , n00 = n[0], n01 = n[1], n02 = n[2], n03 = n[3]
    , n10 = n[4], n11 = n[5], n12 = n[6], n13 = n[7]
    , n20 = n[8], n21 = n[9], n22 = n[10], n23 = n[11]
    , n30 = n[12], n31 = n[13], n32 = n[14], n33 = n[15]
    ;
    let m0 = m[0], m1 = m[1], m2 = m[2], m3 = m[3];

    m[0] = m0*n00 + m1*n10 + m2*n20 + m3*n30;
    m[1] = m0*n01 + m1*n11 + m2*n21 + m3*n31;
    m[2] = m0*n02 + m1*n12 + m2*n22 + m3*n32;
    m[3] = m0*n03 + m1*n13 + m2*n23 + m3*n33;
    m0 = m[4], m1 = m[5], m2 = m[6], m3 = m[7];
    m[4] = m0*n00 + m1*n10 + m2*n20 + m3*n30;
    m[5] = m0*n01 + m1*n11 + m2*n21 + m3*n31;
    m[6] = m0*n02 + m1*n12 + m2*n22 + m3*n32;
    m[7] = m0*n03 + m1*n13 + m2*n23 + m3*n33;
    m0 = m[8], m1 = m[9], m2 = m[10], m3 = m[11];
    m[8] = m0*n00 + m1*n10 + m2*n20 + m3*n30;
    m[9] = m0*n01 + m1*n11 + m2*n21 + m3*n31;
    m[10] = m0*n02 + m1*n12 + m2*n22 + m3*n32;
    m[11] = m0*n03 + m1*n13 + m2*n23 + m3*n33;
    m0 = m[12], m1 = m[13], m2 = m[14], m3 = m[15];
    m[12] = m0*n00 + m1*n10 + m2*n20 + m3*n30;
    m[13] = m0*n01 + m1*n11 + m2*n21 + m3*n31;
    m[14] = m0*n02 + m1*n12 + m2*n22 + m3*n32;
    m[15] = m0*n03 + m1*n13 + m2*n23 + m3*n33;
    return this;
  }

  pre_translate(x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    const m00 = m[0], m01 = m[1], m02 = m[2], m03 = m[3]
    , m10 = m[4], m11 = m[5], m12 = m[6], m13 = m[7]
    , m20 = m[8], m21 = m[9], m22 = m[10], m23 = m[11]
    , m30 = m[12], m31 = m[13], m32 = m[14], m33 = m[15]
    ;

    m[0] = m00 + x*m03;
    m[1] = m01 + y*m03;
    m[2] = m02 + z*m03;
    m[4] = m10 + x*m13;
    m[5] = m11 + y*m13;
    m[6] = m12 + z*m13;
    m[8] = m20 + x*m23;
    m[9] = m21 + y*m23;
    m[10] = m22 + z*m23;
    m[12] = m30 + x*m33;
    m[13] = m31 + y*m33;
    m[14] = m32 + z*m33;
    return this;
  }
  
  pre_scale(x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    m[0] *= x;
    m[1] *= y;
    m[2] *= z;
    m[4] *= x;
    m[5] *= y;
    m[6] *= z;
    m[8] *= x;
    m[9] *= y;
    m[10] *= z;
    m[12] *= x;
    m[13] *= y;
    m[14] *= z;
    return this;
  }

  pre_rotx(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m01 = m[1], m02 = m[2], m11 = m[5], m12 = m[6] 
    , m21 = m[9], m22 = m[10], m31 = m[13], m32 = m[14]
    ;

    m[1] = c*m01 - s*m02; m[2] = s*m01 + c*m02;
    m[5] = c*m11 - s*m12; m[6] = s*m11 + c*m12;
    m[9] = c*m21 - s*m22; m[10] = s*m21 + c*m22;
    m[13] = c*m31 - s*m32; m[14] = s*m31 + c*m32;
    return this;
  }

  pre_roty(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m00 = m[0], m02 = m[2], m10 = m[4], m12 = m[6]
    , m20 = m[8], m22 = m[10], m30 = m[12], m32 = m[14]
    ;
    
    m[0] = c*m00 + s*m02; m[2] = c*m02 - s*m00;
    m[4] = c*m10 + s*m12; m[6] = c*m12 - s*m10;
    m[8] = c*m20 + s*m22; m[10] = c*m22 - s*m20;
    m[12] = c*m30 + s*m32; m[14] = c*m32 - s*m30;
    return this;
  };

  pre_rotz(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m00 = m[0], m01 = m[1], m10 = m[4], m11 = m[5]
    , m20 = m[8], m21 = m[9], m30 = m[12], m31 = m[13] 
    ;

    m[0] = c*m00 - s*m01; m[1] = s*m00 + c*m01;
    m[4] = c*m10 - s*m11; m[5] = s*m10 + c*m11;
    m[8] = c*m20 - s*m21; m[9] = s*m20 + c*m21;
    m[12] = c*m30 - s*m31; m[13] = s*m30 + c*m31;
    return this;
  }
  
  pre_rotax(r : number, x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    const l = 1 / Math.sqrt(x*x + y*y + z*z);
    x *= l; y *= l; z *= l;

    const a00 = m[0], a01 = m[1], a02 = m[2], a03 = m[3]
    , a10 = m[4], a11 = m[5], a12 = m[6], a13 = m[7]
    , a20 = m[8], a21 = m[9], a22 = m[10], a23 = m[11]
    , a30 = m[12], a31 = m[13], a32 = m[14], a33 = m[15]
    , s = Math.sin(r), c = Math.cos(r), t = 1 - c
    , b00 = x*x*t + c, b01 = y*x*t + z*s, b02 = z*x*t - y*s
    , b10 = x*y*t - z*s, b11 = y*y*t + c, b12 = z*y*t + x*s
    , b20 = x*z*t + y*s, b21 = y*z*t - x*s, b22 = z*z*t + c;

    m[0] = a00*b00 + a01*b10 + a02*b20;
    m[1] = a00*b01 + a01*b11 + a02*b21;
    m[2] = a00*b02 + a01*b12 + a02*b22;
    m[4] = a10*b00 + a11*b10 + a12*b20;
    m[5] = a10*b01 + a11*b11 + a12*b21;
    m[6] = a10*b02 + a11*b12 + a12*b22;
    m[8] = a20*b00 + a21*b10 + a22*b20;
    m[9] = a20*b01 + a21*b11 + a22*b21;
    m[10] = a20*b02 + a21*b12 + a22*b22;
    m[12] = a30*b00 + a31*b10 + a32*b20;
    m[13] = a30*b01 + a31*b11 + a32*b21;
    m[14] = a30*b02 + a31*b12 + a32*b22;
    return this;
  }  

  pre_rotpnt(r : number, x : number, y : number, z : number, u : number, v : number, w : number) : Mat4 {
    let m = this.mat4;
    const l = 1 / Math.sqrt(u*u + v*v + w*w);
    u *= l; v *= l; w *= l;

    const a00 = m[0], a01 = m[1], a02 = m[2], a03 = m[3]
    , a10 = m[4], a11 = m[5], a12 = m[6], a13 = m[7]
    , a20 = m[8], a21 = m[9], a22 = m[10], a23 = m[11]
    , a30 = m[12], a31 = m[13], a32 = m[14], a33 = m[15]
    , s = Math.sin(r), c = Math.cos(r), t = 1 - c
    , b00 = u*u + (v*v + w*w)*c, b01 = u*v*t + w*s, b02 = u*w*t - v*s
    , b10 = u*v*t - w*s, b11 = v*v + (u*u + w*w)*c, b12 = v*w*t + u*s
    , b20 = u*w*t + v*s, b21 = v*w*t - u*s, b22 = w*w + (u*u + v*v)*c
    , b30 = (x*(v*v + w*w) - u*(y*v + z*w))*t + (y*w - z*v)*s
    , b31 = (y*(u*u + w*w) - v*(x*u + z*w))*t + (z*u - x*w)*s
    , b32 = (z*(u*u + v*v) - w*(x*u + y*v))*t + (x*v - y*u)*s
    ;

    m[0] = a00*b00 + a01*b10 + a02*b20 + a03*b30;
    m[1] = a00*b01 + a01*b11 + a02*b21 + a03*b31;
    m[2] = a00*b02 + a01*b12 + a02*b22 + a03*b32;
    m[4] = a10*b00 + a11*b10 + a12*b20 + a13*b30;
    m[5] = a10*b01 + a11*b11 + a12*b21 + a13*b31;
    m[6] = a10*b02 + a11*b12 + a12*b22 + a13*b32;
    m[8] = a20*b00 + a21*b10 + a22*b20 + a23*b30;
    m[9] = a20*b01 + a21*b11 + a22*b21 + a23*b31;
    m[10] = a20*b02 + a21*b12 + a22*b22 + a23*b32;
    m[12] = a30*b00 + a31*b10 + a32*b20 + a33*b30;
    m[13] = a30*b01 + a31*b11 + a32*b21 + a33*b31;
    m[14] = a30*b02 + a31*b12 + a32*b22 + a33*b32;
    return this;
  }

  post_mul(that : Mat4) : Mat4 {
    let m = this.mat4;
    const n = that.mat4
    , m00 = m[0], m01 = m[1], m02 = m[2], m03 = m[3]
    , m10 = m[4], m11 = m[5], m12 = m[6], m13 = m[7]
    , m20 = m[8], m21 = m[9], m22 = m[10], m23 = m[11]
    , m30 = m[12], m31 = m[13], m32 = m[14], m33 = m[15]
    ;
    let n0 = n[0], n1 = n[1], n2 = n[2], n3 = n[3];

    m[0] = n0*m00 + n1*m10 + n2*m20 + n3*m30;
    m[1] = n0*m01 + n1*m11 + n2*m21 + n3*m31;
    m[2] = n0*m02 + n1*m12 + n2*m22 + n3*m32;
    m[3] = n0*m03 + n1*m13 + n2*m23 + n3*m33;
    n0 = n[4], n1 = n[5], n2 = n[6], n3 = n[7];
    m[4] = n0*m00 + n1*m10 + n2*m20 + n3*m30;
    m[5] = n0*m01 + n1*m11 + n2*m21 + n3*m31;
    m[6] = n0*m02 + n1*m12 + n2*m22 + n3*m32;
    m[7] = n0*m03 + n1*m13 + n2*m23 + n3*m33;
    n0 = n[8], n1 = n[9], n2 = n[10], n3 = n[11];
    m[8] = n0*m00 + n1*m10 + n2*m20 + n3*m30;
    m[9] = n0*m01 + n1*m11 + n2*m21 + n3*m31;
    m[10] = n0*m02 + n1*m12 + n2*m22 + n3*m32;
    m[11] = n0*m03 + n1*m13 + n2*m23 + n3*m33;
    n0 = n[12], n1 = n[13], n2 = n[14], n3 = n[15];
    m[12] = n0*m00 + n1*m10 + n2*m20 + n3*m30;
    m[13] = n0*m01 + n1*m11 + n2*m21 + n3*m31;
    m[14] = n0*m02 + n1*m12 + n2*m22 + n3*m32;
    m[15] = n0*m03 + n1*m13 + n2*m23 + n3*m33;
    return this;
  }
 
  post_translate(x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    const m00 = m[0], m01 = m[1], m02 = m[2], m03 = m[3]
    , m10 = m[4], m11 = m[5], m12 = m[6], m13 = m[7]
    , m20 = m[8], m21 = m[9], m22 = m[10], m23 = m[11]
    ;

    m[12] = x*m00 + y*m10 + z*m20 + m[12];
    m[13] = x*m01 + y*m11 + z*m21 + m[13];
    m[14] = x*m02 + y*m12 + z*m22 + m[14];
    m[15] = x*m03 + y*m13 + z*m23 + m[15];
    return this;
  }

  post_scale(x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    m[ 0] *= x;
    m[ 1] *= x;
    m[ 2] *= x;
    m[ 3] *= x;
    m[ 4] *= y;
    m[ 5] *= y;
    m[ 6] *= y;
    m[ 7] *= y;
    m[ 8] *= z;
    m[ 9] *= z;
    m[10] *= z;
    m[11] *= z;
    return this;
  }

  post_rotx(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m10 = m[4], m11 = m[5], m12 = m[6], m13 = m[7]
    , m20 = m[8], m21 = m[9], m22 = m[10], m23 = m[11]
    ;

    m[4] = c*m10 + s*m20; m[5] = c*m11 + s*m21; 
    m[6] = c*m12 + s*m22; m[7] = c*m13 + s*m23;
    m[8] = c*m20 - s*m10; m[9] = c*m21 - s*m11;
    m[10] = c*m22 - s*m12; m[11] = c*m23 - s*m13;
    return this;
  }

  post_roty(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m00 = m[0], m01 = m[1], m02 = m[2], m03 = m[3]
    , m20 = m[8], m21 = m[9], m22 = m[10], m23 = m[11]
    ;

    m[0] = c*m00 - s*m20; m[1] = c*m01 - s*m21;
    m[2] = c*m02 - s*m22; m[3] = c*m03 - s*m23;
    m[8] = s*m00 + c*m20; m[9] = s*m01 + c*m21;
    m[10] = s*m02 + c*m22; m[11] = s*m03 + c*m23;
    return this;
  }

  post_rotz(r : number) : Mat4 {
    let m = this.mat4;
    const s = Math.sin(r), c = Math.cos(r)
    , m00 = m[0], m01 = m[1], m02 = m[2], m03 = m[3]
    , m10 = m[4], m11 = m[5], m12 = m[6], m13 = m[7]
    ;

    m[0] = c*m00 + s*m10; m[1] = c*m01 + s*m11;
    m[2] = c*m02 + s*m12; m[3] = c*m03 + s*m13;
    m[4] = c*m10 - s*m00; m[5] = c*m11 - s*m01;
    m[6] = c*m12 - s*m02; m[7] = c*m13 - s*m03;
    return this;
  }
 
  post_rotax(r : number, x : number, y : number, z : number) : Mat4 {
    let m = this.mat4;
    const l = 1 / Math.sqrt(x*x + y*y + z*z);
    x *= l; y *= l; z *= l;

    const a00 = m[0], a01 = m[1], a02 = m[2], a03 = m[3]
    , a10 = m[4], a11 = m[5], a12 = m[6], a13 = m[7]
    , a20 = m[8], a21 = m[9], a22 = m[10], a23 = m[11]
    , s = Math.sin(r), c = Math.cos(r), t = 1 - c
    , b00 = x*x*t + c, b01 = y*x*t + z*s, b02 = z*x*t - y*s
    , b10 = x*y*t - z*s, b11 = y*y*t + c, b12 = z*y*t + x*s
    , b20 = x*z*t + y*s, b21 = y*z*t - x*s, b22 = z*z*t + c;

    m[0] = a00*b00 + a10*b01 + a20*b02;
    m[1] = a01*b00 + a11*b01 + a21*b02;
    m[2] = a02*b00 + a12*b01 + a22*b02;
    m[3] = a03*b00 + a13*b01 + a23*b02;
    m[4] = a00*b10 + a10*b11 + a20*b12;
    m[5] = a01*b10 + a11*b11 + a21*b12;
    m[6] = a02*b10 + a12*b11 + a22*b12;
    m[7] = a03*b10 + a13*b11 + a23*b12;
    m[8] = a00*b20 + a10*b21 + a20*b22;
    m[9] = a01*b20 + a11*b21 + a21*b22;
    m[10] = a02*b20 + a12*b21 + a22*b22;
    m[11] = a03*b20 + a13*b21 + a23*b22;
    return this;
  }  

  post_rotpnt(r : number, x : number, y : number, z : number, u : number, v : number, w : number) : Mat4 {
    let m = this.mat4;
    const l = 1 / Math.sqrt(u*u + v*v + w*w);
    u *= l; v *= l; w *= l;

    const a00 = m[0], a01 = m[1], a02 = m[2], a03 = m[3]
    , a10 = m[4], a11 = m[5], a12 = m[6], a13 = m[7]
    , a20 = m[8], a21 = m[9], a22 = m[10], a23 = m[11]
    , a30 = m[12], a31 = m[13], a32 = m[14], a33 = m[15]
    , s = Math.sin(r), c = Math.cos(r), t = 1 - c
    , b00 = u*u + (v*v + w*w)*c, b01 = u*v*t + w*s, b02 = u*w*t - v*s
    , b10 = u*v*t - w*s, b11 = v*v + (u*u + w*w)*c, b12 = v*w*t + u*s
    , b20 = u*w*t + v*s, b21 = v*w*t - u*s, b22 = w*w + (u*u + v*v)*c
    , b30 = (x*(v*v + w*w) - u*(y*v + z*w))*t + (y*w - z*v)*s
    , b31 = (y*(u*u + w*w) - v*(x*u + z*w))*t + (z*u - x*w)*s
    , b32 = (z*(u*u + v*v) - w*(x*u + y*v))*t + (x*v - y*u)*s
    ;

    m[0] = a00*b00 + a10*b01 + a20*b02;
    m[1] = a01*b00 + a11*b01 + a21*b02;
    m[2] = a02*b00 + a12*b01 + a22*b02;
    m[3] = a03*b00 + a13*b01 + a23*b02;
    m[4] = a00*b10 + a10*b11 + a20*b12;
    m[5] = a01*b10 + a11*b11 + a21*b12;
    m[6] = a02*b10 + a12*b11 + a22*b12;
    m[7] = a03*b10 + a13*b11 + a23*b12;
    m[8] = a00*b20 + a10*b21 + a20*b22;
    m[9] = a01*b20 + a11*b21 + a21*b22;
    m[10] = a02*b20 + a12*b21 + a22*b22;
    m[11] = a03*b20 + a13*b21 + a23*b22;
    m[12] = a00*b30 + a10*b31 + a20*b32 + a30;
    m[13] = a01*b30 + a11*b31 + a21*b32 + a31;
    m[14] = a02*b30 + a12*b31 + a22*b32 + a32;
    m[15] = a03*b30 + a13*b31 + a23*b32 + a33;
    return this;
  }

  constructor() {
    this.mat4 = new Float32Array(16);
  }
}

//----------------------------------------------------------------------------
// 3x3 Matrix

export class Mat3 {

  mat3 : Float32Array;

  invert() : Mat3 {
    let m = this.mat3;
    const a00 = m[0], a01 = m[1], a02 = m[2] 
    , a10 = m[3], a11 = m[4], a12 = m[5]
    , a20 = m[6], a21 = m[7], a22 = m[8]
    , b01 = a22*a11 - a12*a21
    , b11 = a12*a20 - a22*a10
    , b21 = a21*a10 - a11*a20
    , det = 1.0 / (a00*b01 + a01*b11 + a02*b21)
    ;
    m[0] = b01 * det;
    m[1] = (a02*a21 - a22*a01) * det;
    m[2] = (a12*a01 - a02*a11) * det;
    m[3] = b11 * det;
    m[4] = (a22*a00 - a02*a20) * det;
    m[5] = (a02*a10 - a12*a00) * det;
    m[6] = b21 * det;
    m[7] = (a01*a20 - a21*a00) * det;
    m[8] = (a11*a00 - a01*a10) * det;
    return this;
  }

  set_identity() : Mat3 {
     let m = this.mat3; 

     m[0] = 1; m[1] = 0; m[2] = 0;
     m[3] = 0; m[4] = 1; m[5] = 0;
     m[6] = 0; m[7] = 0; m[8] = 1;

     return this;
  }

  set_mat4(that : Mat4) : Mat3 {
    let m = this.mat3;
    const n = that.mat4;

    m[0] = n[0];
    m[1] = n[1];
    m[2] = n[2];
    m[3] = n[4];
    m[4] = n[5];
    m[5] = n[6];
    m[6] = n[8];
    m[7] = n[9];
    m[8] = n[10];

    return this;
  }

  set_invert(that : Mat3) : Mat3 {
    let m = this.mat3;
    const n = that.mat3
    , a00 = n[0], a01 = n[1], a02 = n[2] 
    , a10 = n[3], a11 = n[4], a12 = n[5]
    , a20 = n[6], a21 = n[7], a22 = n[8]
    , b01 = a22*a11 - a12*a21
    , b11 = a12*a20 - a22*a10
    , b21 = a21*a10 - a11*a20
    , det = 1.0 / (a00*b01 + a01*b11 + a02*b21)
    ;

    m[0] = b01 * det;
    m[1] = (a02*a21 - a22*a01) * det;
    m[2] = (a12*a01 - a02*a11) * det;
    m[3] = b11 * det;
    m[4] = (a22*a00 - a02*a20) * det;
    m[5] = (a02*a10 - a12*a00) * det;
    m[6] = b21 * det;
    m[7] = (a01*a20 - a21*a00) * det;
    m[8] = (a11*a00 - a01*a10) * det;

    return this;
  }

  set_normalize(that : Mat3) : Mat3 {
    let m = this.mat3;
    const n = that.mat3
    , a00 = n[0], a01 = n[1], a02 = n[2]
    , a10 = n[3], a11 = n[4], a12 = n[5]
    , a20 = n[6], a21 = n[7], a22 = n[8]
    , det = 1.0 / (a00*(a22*a11 - a12*a21)
      + a01*(a12*a20 - a22*a10)
      + a02*(a21*a10 - a11*a20)
      )
    ;

    m[0] = det*a00;
    m[1] = det*a01;
    m[2] = det*a02;
    m[3] = det*a10;
    m[4] = det*a11;
    m[5] = det*a12;
    m[6] = det*a20;
    m[7] = det*a21;
    m[8] = det*a22;

    return this;
  }

  set_transpose(that : Mat3) : Mat3 {
    let m = this.mat3;
    const n = that.mat3;

    if (m === n) {
      const a01 = n[1], a02 = n[2], a12 = n[5];
      m[1] = n[3]; m[2] = n[6]; m[5] = n[7];
      m[3] = a01; m[6] = a02; m[7] = a12;
    } else {
      m[0] = n[0]; m[1] = n[3]; m[2] = n[6];
      m[3] = n[1]; m[4] = n[4]; m[5] = n[7];
      m[6] = n[2]; m[7] = n[5]; m[8] = n[8];
    }

    return this;
  }

  set_multiply(left : Mat3, right : Mat3) : Mat3 {
      let m = this.mat3;
      const p = left.mat3, q = right.mat3
      , p00 = p[0], p01 = p[1], p02 = p[2]
      , p10 = p[3], p11 = p[4], p12 = p[5]
      , p20 = p[6], p21 = p[7], p22 = p[8]
      , q00 = q[0], q01 = q[1], q02 = q[2]
      , q10 = q[3], q11 = q[4], q12 = q[5]
      , q20 = q[6], q21 = q[7], q22 = q[8]
      ;
      m[0] = p00*q00 + p01*q10 + p02*q20;
      m[1] = p00*q01 + p01*q11 + p02*q21;
      m[2] = p00*q02 + p01*q12 + p02*q22;
      m[3] = p10*q00 + p11*q10 + p12*q20;
      m[4] = p10*q01 + p11*q11 + p12*q21;
      m[5] = p10*q02 + p11*q12 + p12*q22;
      m[6] = p20*q00 + p21*q10 + p22*q20;
      m[7] = p20*q01 + p21*q11 + p22*q21;
      m[8] = p20*q02 + p21*q12 + p22*q22;
      return this;
  }

  // 1 0 0   00 01 02
  // 0 1 0 x 10 11 12
  // x y 1   20 21 22

  pre_translate(x : number, y : number) : Mat3 {
    let m = this.mat3;
    const a00 = m[0], a01 = m[1], a02 = m[2]
    , a10 = m[3], a11 = m[4], a12 = m[5]
    , a20 = m[6], a21 = m[7], a22 = m[8]
    ;

    m[6] = x*a00 + y*a10 + a20;
    m[7] = x*a01 + y*a11 + a21;
    m[8] = x*a02 + y*a12 + a22;

    return this;
  }
   
  // 00 01 02   1 0 0
  // 10 11 12 x 0 1 0
  // 20 21 22   x y 1

  post_translate(x : number, y : number) : Mat3 {
    let m = this.mat3;
    const a02 = m[2]
    , a12 = m[5]
    , a22 = m[8]
    ;

    m[0] += x*a02;
    m[1] += y*a02;
    m[3] += x*a12;
    m[4] += y*a12;
    m[6] += x*a22;
    m[7] += y*a22;

    return this;
  }

  // +c -s  0   00 01 02
  // +s +c  0 x 10 11 12
  //  0  0  1   20 21 22

  pre_rotate(a : number) : Mat3 {
    let m = this.mat3;
    const a00 = m[0], a01 = m[1], a02 = m[2]
    , a10 = m[3], a11 = m[4], a12 = m[5]
    , s = Math.sin(a)
    , c = Math.cos(a)
    ;

    m[0] = c*a00 - s*a10;
    m[1] = c*a01 - s*a11;
    m[2] = c*a02 - s*a12;
    m[3] = s*a00 + c*a10;
    m[4] = s*a01 + c*a11;
    m[5] = s*a02 + c*a12;

    return this;
  }

  //  1  0 0   +c -s 0   1 0 0   00 01 02
  //  0  1 0 x +s +c 0 x 0 1 0 x 10 11 12
  // -x -y 1    0  0 1   x y 1   20 21 22
  //
  //           00           01           02
  //           10           11           12
  // 00.x+10.y+20 01.x+11.y+21 02.x+12.y+22
  //
  //    c.00-s.10    c.01-s.11    c.02-s.12
  //    s.00+c.10    s.01+c.11    s.02+c.12
  // 00.x+10.y+20 01.x+11.y+21 02.x+12.y+22
  //
  //                                c.00-s.10                                c.01-s.11                                c.02-s.12
  //                                s.00+c.10                                s.01+c.11                                s.02+c.12
  // 00.x+10.y+20-x.(c.00-s.10)-y.(s.00+c.10) 01.x+11.y+21-x.(c.01-s.11)-y.(s.01+c.11) 02.x+12.y+22-x.(c.02-s.12)-y.(s.02+c.12)  
 
  pre_rotate_at(a : number, x : number, y : number) : Mat3 {
      let m = this.mat3;
      const m00 = m[0], m01 = m[1], m02 = m[2]
      , m10 = m[3], m11 = m[4], m12 = m[5]
      , m20 = m[6], m21 = m[7], m22 = m[8]
      , s = Math.sin(a)
      , c = Math.cos(a)
      , c00s10 = c*m00-s*m10
      , c01s11 = c*m01-s*m11
      , c02s12 = c*m02-s*m12
      , s00c10 = s*m00+c*m10
      , s01c11 = s*m01+c*m11
      , s02c12 = s*m02+c*m12
      ;
      m[0] = c00s10;
      m[1] = c01s11;
      m[2] = c02s12;
      m[3] = s00c10;
      m[4] = s01c11;
      m[5] = s02c12;
      m[6] = x*m00 + y*m10 + m20 - x*(c00s10) - y*(s00c10);
      m[7] = x*m01 + y*m11 + m21 - x*(c01s11) - y*(s01c11);
      m[8] = x*m02 + y*m12 + m22 - x*(c02s12) - y*(s02c12);
      return this;
  }

  post_rotate(a : number) : Mat3 {
    let m = this.mat3;
    const a00 = m[0], a01 = m[1]
    , a10 = m[3], a11 = m[4]
    , a20 = m[6], a21 = m[7]
    , s = Math.sin(a)
    , c = Math.cos(a)
    ;

    m[0] = c*a00 + s*a01;
    m[1] = c*a01 - s*a00;
    m[3] = c*a10 + s*a11;
    m[4] = c*a11 - s*a10;
    m[6] = c*a20 + s*a21;
    m[7] = c*a21 - s*a20;

    return this;
  }

  // sx  0  0   00 01 02
  //  0 sy  0 x 10 11 12
  //  0  0  1   20 21 22

  pre_scale(x : number, y : number) : Mat3 {
    let m = this.mat3;
    
    m[0] *= x;
    m[1] *= x;
    m[2] *= x; 
    m[3] *= y;
    m[4] *= y;
    m[5] *= y; 

    return this;
  }

  //  1  0 0   sx  0 0   1 0 0   00 01 02
  //  0  1 0 x  0 sy 0 x 0 1 0 x 10 11 12
  // -x -y 1    0  0 1   x y 1   20 21 22
  //
  //           00           01           02
  //           10           11           12
  // 00.x+10.y+20 01.x+11.y+21 02.x+12.y+22
  //
  //        00.sx        01.sx        02.sx
  //        10.sy        11.sy        12.sy
  // 00.x+10.y+20 01.x+11.y+21 02.x+21.y+22
  //
  // 00.sx 01.sx 02.sx
  // 10.sy 11.sy 12.sy
  // 00.sx.-x + 10.sy.-y + 00.x+10.y+20 01.sx.-x + 11.sy.-y + 01.x+11.y+21 02.sx.-x + 12.sy.-y + 02.x+21.y+22

  pre_scale_at(sx : number, sy : number, x : number, y : number) : Mat3 {
      let m = this.mat3;
      const m00 = m[0], m01 = m[1], m02 = m[2]
      , m10 = m[3], m11 = m[4], m12 = m[5]
      , m20 = m[6], m21 = m[7], m22 = m[8]
      , sx00 = sx * m00
      , sx01 = sx * m01
      , sx02 = sx * m02
      , sy10 = sy * m10
      , sy11 = sy * m11
      , sy12 = sy * m12
      ;
      m[0] = sx00;
      m[1] = sx01;
      m[2] = sx02;
      m[3] = sy10;
      m[4] = sy11;
      m[5] = sy12;
      m[6] = x*m00 + y*m10 + m20 - x*sx00 - y*sy10;
      m[7] = x*m01 + y*m11 + m21 - x*sx01 - y*sy11;
      m[8] = x*m02 + y*m12 + m22 - x*sx02 - y*sy12;
      return this;
  }
  
  post_scale(x : number, y : number) : Mat3 {
    let m = this.mat3;

    m[0] *= x;
    m[1] *= y;
    m[3] *= x;
    m[4] *= y;
    m[6] *= x;
    m[7] *= y;

    return this;
  }

  copy(that : Mat3) : Mat3 {
    let m = this.mat3;
    const n = that.mat3;

    m[0] = n[0]; m[1] = n[1]; m[2] = n[2];
    m[3] = n[3]; m[4] = n[4]; m[5] = n[5];
    m[6] = n[6]; m[7] = n[7]; m[8] = n[8];

    return this;
  }

  limit_rect(x0 : number, y0 : number, x1 : number, y1 : number) : Mat3 {
    let m = this.mat3;
    const x = m[6]
    , y = m[7]
    ;

    if (x < x0) {
        m[6] = x0;
    } else if (x >= x1) {
        m[6] = x1 - 1;
    }

    if (y < y0) {
        m[7] = y0;
    } else if (y >= y1) {
        m[7] = y1 - 1;
    }

    return this;
  }

  constructor() {
    this.mat3 = new Float32Array(9);
  }
}

